<template>
<div id="page-traspasos-inventario-enviados" class="non-printable">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height class="non-printable">
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Traspasos de Inventario Enviados" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModalAjuste(1)" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                    <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                    </template>
                    <template slot="fecha" slot-scope="props">
                        {{ parse_date_time(props.row.fecha) }}
                    </template>
                    <template slot="fecha_finalizado" slot-scope="props">
                        {{ parse_date_time(props.row.fecha_finalizado) }}
                    </template>
                    <template slot="articulos" slot-scope="props">
                        <v-chip color="blue" label outlined x-small @click="verArticulos(props.row)">
                            Ver {{ props.row.articulos.length }} artículos
                        </v-chip>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-speed-dial direction="left" open-on-hover>
                            <template v-slot:activator>
                            <v-btn x-small fab dark color="primary">
                                <v-icon v-if="fab[props.row._id]">mdi-close</v-icon>
                                <v-icon v-else>reorder</v-icon>
                            </v-btn>
                            </template>
                            <v-btn x-small fab dark color="blue-grey lighten-3" v-if="props.row.estatus=='Pendiente'"
                                @click.native="editarTraspaso(props.row)" title="Editar Traspaso" v-tippy>
                                <v-icon>edit</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="primary"
                                v-if="['Salida de Origen','En Proceso de Entrada', 'Recibido'].includes(props.row.estatus)"
                                @click.native="imprimirOrdenEntrada(props.row)" title="Orden Entrada" v-tippy>
                                <v-icon>mdi-package-down</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="success" @click.native="imprimirOrdenSalida(props.row)" title="Orden Salida" v-tippy>
                                <v-icon>mdi-package-up</v-icon>
                            </v-btn>
                            <!--v-btn x-small fab dark color="red" v-if="props.row.estatus=='Pendiente'"
                                @click.native="cancelarTraspaso(props.row)" title="Cancelar Traspaso" v-tippy>
                                <v-icon>close</v-icon>
                            </v-btn-->
                            <v-btn x-small fab dark color="purple darken-1"
                                v-if="props.row.estatus!='Pendiente'"
                                @click.native="editarObservaciones(props.row)" title="Observaciones" v-tippy>
                                <v-icon>mdi-note-plus</v-icon>
                            </v-btn>
                        </v-speed-dial>

                    </template>

                </v-client-table>
                <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- MODAL AJUSTE -->
  <v-dialog v-model="modalAjuste" max-width="60%" persistent>
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Traspaso de Inventario</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalAjuste = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formajuste" lazy-validation>
          <v-container grid-list-md>
            <v-row>
                <v-text-field label="Sucursal Origen" v-model="model.nombre_sucursal_origen" readonly></v-text-field>

                <v-col sm="1" md="1" lg="1" align-self="center"><v-icon>arrow_right_alt</v-icon></v-col>

                <v-autocomplete v-model="model.id_sucursal_destino" :items="sucursales" :hide-no-data="true"
                    :hide-selected="true" item-text="nombre" item-value="_id"
                    label="Sucursal destino" :rules="[rules.required]" :readonly="model.articulos.length>0">
                </v-autocomplete>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-autocomplete v-model="id_articulo" :items="articulos" :hide-no-data="true"
                    :loading="isLoading" ref="selectarticulo"                    
                    placeholder="Seleccione artículo"
                    prepend-icon="search"
                    :hide-selected="true" :item-text="get_nombre_articulo" item-value="_id" @change="consultar()"
                    label="Artículo" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="1" lg="1"></v-col>
                <v-col sm="12" md="3" lg="3">
                    <v-text-field label="Actual" v-model="actual" readonly ></v-text-field>
                </v-col>
                <v-col sm="12" md="4" lg="4">
                    <v-text-field label="Cantidad a Traspasar" v-model="cantidad" ref="cantidadtraspaso"
                    :rules="[rules.required, validaCantidadFloat, validaActual]" ></v-text-field>
                </v-col>
                <v-col sm="12" md="4" lg="4" align-self="center">
                    <v-btn small color="secondary" @click.native="agregarArticulo()">
                        <v-icon>add</v-icon> Agregar Artículo
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-client-table ref="vuetablearticulos" :data="model.articulos" :columns="columnsArticulos" :options="optionsArticulos" class="elevation-2 pa-2">
                        <template slot="actions" slot-scope="props">
                            <v-btn x-small fab dark color="pink" @click.native="deleteArticulo(props.row.id_articulo)" title="Descartar Artículo" v-tippy>
                                <v-icon>delete</v-icon>
                            </v-btn>
                            &nbsp;
                            <v-btn x-small fab dark color="teal darken-1" @click.native="editArticulo(props.row)" title="Editar Cantidad" v-tippy>
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                    </v-client-table>
                </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalAjuste=false" v-if="model.articulos.length==0">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
        <!--v-btn @click.native="cancelarTraspaso(model)" v-if="model.articulos.length>0">
          <v-icon>cancel</v-icon> Cancelar Traspaso
        </v-btn-->
        <v-btn color="success" @click.native="guardarTraspaso()" v-if="model.articulos.length>0">
          <v-icon>done</v-icon> Guardar Traspaso
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL ARTICULOS -->
  <v-dialog v-model="modalArticulos" max-width="80%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Artículos en Traspaso de Inventario</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalArticulos = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
          <v-container grid-list-md>
            <v-row>
                <v-col lg="6" md="6" sm="6" v-show="model_vista.usuario_salida!=null && model_vista.usuario_salida!=''">
                    <b>Salida de Origen:</b><br>
                    Usuario:&nbsp; {{model_vista.usuario_salida}}<br>
                    Fecha:&nbsp; {{ parse_date_time(model_vista.fecha_salida) }}<br>
                    Observaciones:&nbsp; {{model_vista.observacion_salida}}
                </v-col>
                <v-col lg="6" md="6" sm="6" v-show="model_vista.usuario_entrada!=null && model_vista.usuario_entrada!=''">
                    <b>Entrada a Destino:</b><br>
                    Usuario:&nbsp; {{model_vista.usuario_entrada}}<br>
                    Fecha:&nbsp; {{ parse_date_time(model_vista.fecha_finalizado) }}<br>
                    Observaciones:&nbsp; {{model_vista.observacion_entrada}}
                </v-col>
            </v-row><br>
            <v-row>
                <v-col>
                    <v-client-table ref="vuetablearticulos" :data="articulos_vista" :columns="columnsArticulosVista" :options="optionsArticulos" class="elevation-2 pa-2">
                        <template slot="codigo_articulo" slot-scope="props">
                            {{ props.row.codigo_articulo.join(", ") }}
                        </template>
                        <template slot="cantidad" slot-scope="props">
                            <div style="text-align:right;">{{ props.row.cantidad }}</div>
                        </template>
                        <template slot="cantidad_salida" slot-scope="props"
                            v-if="model_vista.estatus!='Pendiente'">
                            <div style="text-align:right;" :class="props.row.cantidad_salida!=props.row.cantidad?'bg-diferente':'bg-correcto'">{{ props.row.cantidad_salida }}</div>
                        </template>
                        <template slot="cantidad_recibida" slot-scope="props"
                            v-if="['En Proceso de Entrada','Recibido'].includes(model_vista.estatus)">
                            <div style="text-align:right;" :class="props.row.cantidad_recibida!=props.row.cantidad?'bg-diferente':'bg-correcto'">{{ props.row.cantidad_recibida }}</div>
                        </template>
                    </v-client-table>
                </v-col>
            </v-row>
          </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalArticulos=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL EDITAR CANTIDAD ARTICULO -->
  <v-dialog v-model="modalEditable" max-width="60%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Editar cantidad de Artículo</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formeditable" lazy-validation>
          <v-container grid-list-md>
              <v-row>
                <v-col sm="12" md="12" lg="12">
                    <v-text-field label="Articulo" v-model="modelEditable.nombre_articulo" readonly ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="2" lg="2"></v-col>
                <v-col sm="12" md="4" lg="4">
                    <v-text-field label="Actual" v-model="actualEditable" readonly ></v-text-field>
                </v-col>
                <v-col sm="12" md="4" lg="4">
                    <v-text-field label="Cantidad a Traspasar" v-model="cantidadEditable"
                    :rules="[rules.required, validaCantidadFloat, validaActualEditable]" ></v-text-field>
                </v-col>
                <v-col sm="12" md="2" lg="2"></v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalEditable=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
        <v-btn color="success" @click.native="saveNuevaCantidadArticulo()">
          <v-icon>done</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL OBSERVACION -->
  <v-dialog v-model="modalObservacion" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Editar Observaciones</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formobservacion" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-textarea outlined label="Observaciones de salida de mercancía" rows="4" v-model="model.observacion_salida" ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-textarea outlined label="Observaciones de entrada de mercancía" rows="4" v-model="model.observacion_entrada" ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalObservacion=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
        &nbsp;&nbsp;
        <v-btn color="success" @click.native="guardarObservaciones()">
          <v-icon>done</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
import Vue from "vue";
import "jsbarcode";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
export default {
  components: {
    "PaginacionComponent": PaginacionComponent,
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
    search (val) {

        //Si no ha escrito 4 letras o mas
        if (val == null || val.length<4 ) return

        // Si todavía esta buscando
        if (this.isLoading) return

        this.isLoading = true

        let data = {
                    "selector": {
                        "type":"articulos",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus": "Activo"
                    },
                    /*"fields": [
                        "nombre", "estatus"
                    ]*/
                    };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        this.articulos = response.data.docs;
                    }else
                        this.articulos = [];

                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });

                }).then(()=>{ this.isLoading=false; });
      },
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('s.inventarios.envio')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
    //Para detectar el cambio de estatus por lista y que busque, sin darle enter
    const select = document.getElementsByName('vf__estatus')[0];
    select.addEventListener('change', this.onStatusChanged);
  },
  created: function() {
        this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
        if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
            this.$router.go();            
        }
        this.model.id_sucursal_origen = this.$local_storage.get("sb_sucursal","");
        this.sb_sucursal = this.$local_storage.get("sb_sucursal","");
        this.model.nombre_sucursal_origen = this.$local_storage.get("sb_nombre_sucursal","");
        this.model.usuario = this.$local_storage.get("sb_usuario");
        this.getSucursales();
        this.getArticulos();

  },
  data() {
    return {
        sucursal_activa:"",
        isLoading:false,
        sb_sucursal:"",
        model_vista: "",
        model_filters: "",
        props_paginacion: {
            infiniteHandler: this.infiniteHandler,
            total_registros: 0
        },
        fab: [],
        modalObservacion:false,
        modalAjuste:false,
        modalEditable:false,
        modalArticulos:false,
        barcode_orden:"",
        row_selected:"",
        articulos_vista:[],
        menu1:false,
        menu2:false,
        name: "datagrid",
        columns: [
            "clave_traspaso",
            "nombre_sucursal_destino",
            "fecha","usuario","articulos","estatus","fecha_finalizado", "actions"
        ],
        options: {
            filterable: ["clave_traspaso",
                "nombre_sucursal_destino","estatus",
                "fecha","usuario","fecha_finalizado"],
            headings: {
                "clave_traspaso":"Clave Traspaso",
                "nombre_sucursal_destino":"Sucursal Destino",
                "fecha_finalizado":"Fecha Recibido",
                "actions":"Operaciones"
            },
            listColumns: {
                estatus: [
                    {id: "Pendiente",text: "Pendiente"},
                    {id: "En Proceso de Salida",text: "En Proceso de Salida"},
                    {id: "Salida de Origen",text: "Salida de Origen"},
                    {id: "En Proceso de Entrada",text: "En Proceso de Entrada"},
                    {id: "Recibido",text: "Recibido"},

                ],
            }

        },
        breadcrumbs: [{
            text: "Inicio",
            disabled: false,
            to: "/index"
            },
            {
                text: "Inventario",
                href: "",
                disabled: true,
            },
            {
                text: "Traspasos de Inventario Enviados",
                href: ""
            }
        ],

        update: false,
        modelEditable:{},
        model:{
            _id:"",
            _rev:"",
            type:"lotes_traspasos",
            clave_traspaso:"",
            id_sucursal_origen:"",
            nombre_sucursal_origen:"",
            id_sucursal_destino:"",
            nombre_sucursal_destino:"",
            articulos:[],
            usuario:"",
            fecha:"",
            usuario_salida:"",
            fecha_salida:"",
            usuario_entrada:"",
            fecha_finalizado:"",
            estatus:"Pendiente", //Pendiente, En Proceso de Salida, Salida de Origen, En Proceso de Entrada, Recibido
            //Arreglo de lotes que se afectaron en sucursal destino,
            // esta opción se llena cuando se finaliza el traspaso en la vista de Traspasos
            lotes_destino: [],
            observacion_salida:"",
            observacion_entrada:"",
        },
        actual:"",
        cantidad:"",
        actualEditable:"",
        cantidadEditable:"",
        id_articulo:"",

        articulo:{
            id_articulo:"",
            nombre_articulo:"",
            codigo_articulo:"",
            cantidad:"",
            lotes:[], //lotes que afecto el articulo en sucursal origen
            estatus:"Pendiente", //Pendiente, Salida, Recibido
            cantidad_salida: 0,  //cantidad real que salió de sucursal origen
            cantidad_recibida: 0,  //cantidad real recibida en destino
            lotes_destino:[], //Esta opción se llena cuando se le da entrada en sucursal destino
        },
        columnsArticulos: [ "codigo_articulo","nombre_articulo","descripcion","cantidad","actions"],
        columnsArticulosVista: [ "codigo_articulo","nombre_articulo","descripcion","cantidad","cantidad_salida","cantidad_recibida"],
        optionsArticulos: {
            filterable: ["codigo_articulo","nombre_articulo","descripcion","cantidad","cantidad_salida","cantidad_recibida"],
            headings: {
                "codigo_articulo":"Código",
                "nombre_articulo":"Artículo",
                "cantidad":"Cantidad a Traspasar",
                "cantidad_salida":"Cantidad Real Salida",
                "cantidad_recibida":"Cantidad Real Recibida",
                "actions":"Opciones",
            },            

        },
        rules: {
            required: v => !!v || "Este campo es requerido",
        },
        search: "",
        registros: {
            selected: [],
            items: []
        },
        sucursales:[],
        all_sucursales:[],
        articulos:[],

    };
  },
  methods: {
        onStatusChanged(event) {
            this.registros.items = []; 
            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        },
      infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      var where = {};
      if(this.sb_sucursal!=null && this.sb_sucursal!="0" && this.sb_sucursal!="")
        where = {"type":"lotes_traspasos","id_sucursal_origen":this.sb_sucursal};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            sort: [{"fecha": "desc"}],
            use_index: "_design/3a6cf449e1ca3b653ee421d2624ac99b071449e9",
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    parse_date(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },
    parse_date_time_mx(date) {
      if (!date) return null;
      return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },

    getNombreArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.nombre;
        else
            return ""
    },
    getNombreSucursal: function(id_sucursal){
        var filter = this.all_sucursales.find(e => e._id==id_sucursal);
        if(filter)
            return filter.nombre;
        else
            return ""
    },
    getLetraSucursal: function(id_sucursal){
        var filter = this.all_sucursales.find(e => e._id==id_sucursal);

        if(filter)
            return filter.clave;
        else
            return "X";
    },

    getCodigoArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.codigo_barras;
        else
            return ""
    },

    getDescripcionArticulo:function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.descripcion;
        else
            return ""
    },

    validaActual: function(val){
        if(this.actual != null && this.actual !="" ){
            if(parseFloat(val) > parseFloat(this.actual))
                return "La cantidad es mayor a la actual";
            if(parseFloat(val) <= 0 )
                return "La cantidad debe ser mayor que 0";
        }
        return true;
    },
    validaActualEditable: function(val){
        if(this.actualEditable != null && this.actualEditable !="" ){
            if(parseFloat(val) > parseFloat(this.actualEditable))
                return "La cantidad es mayor a la actual";
            if(parseFloat(val) <= 0 )
                return "La cantidad debe ser mayor que 0";
        }
        return true;
    },

    verArticulos: function(row){
        this.articulos_vista = row.articulos;
        this.model_vista = row;
        this.modalArticulos=true;
    },

    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },

    getSucursales: function () {
            let data = {
                    "selector": {
                        "type":"sucursales",
                      "estatus": {
                        "$eq": "Activo"
                      }
                    },
                    /*"fields": [
                        "nombre", "estatus"
                    ]*/
                    };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){

                        this.all_sucursales = response.data.docs;
                        var filter = response.data.docs.filter(e => {
                            return e._id != this.model.id_sucursal_origen;
                        });
                        this.sucursales = filter;
                    }
                    else
                        this.sucursales = [];

                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las sucursales.",
                        footer: ""
                    });

                });
        },
    openModalAjuste: function(type) {
      if (type === 1) {
        this.update = false;

        this.id_articulo="";
        this.cantidad="";
        this.actual="";
        this.model._id="";
        this.model._rev="";
        this.model.id_sucursal_destino="";
        this.model.nombre_sucursal_destino="";
        this.model.articulos=[];
        this.model.estatus=""; //Pendiente, En Proceso de Salida, Salida de Origen, En Proceso de Entrada, Recibido
        this.model.lotes_destino=[];

      } else {
        this.update = true;
      }
      this.modalAjuste = true;
    },

    editArticulo: function(row){
        this.cantidadEditable = row.cantidad;
        this.modelEditable = JSON.parse(JSON.stringify(row));
        this.modalEditable = true;
        this.consultarEditable(this.model.id_sucursal_origen,row.id_articulo, row.cantidad);
    },

    saveNuevaCantidadArticulo: function(){
        if (this.$refs.formeditable.validate() && this.cantidadEditable ) {

            try {

                var descartado = this.model.articulos.filter( e => {
                    return e.id_articulo == this.modelEditable.id_articulo;
                });
                if(descartado.length>0){
                    window.dialogLoader.show('Espere un momento por favor..');
                    var lotes = descartado[0].lotes;
                    // llamar cancelarComprometido con los lotes guardados
                    //Regresar el inventario comprometido al actual
                    window.funciones_lotes.cancelarComprometido(lotes).then(result =>{

                        //Eliminar articulo del modelo
                        var filter = this.model.articulos.filter( e => {
                            return e.id_articulo != this.modelEditable.id_articulo;
                        });
                        this.model.articulos = filter;


                        //Generamos nuevo articulo
                        var articuloNew = Vue.util.extend({}, this.articulo);
                        articuloNew.id_articulo = this.modelEditable.id_articulo;
                        articuloNew.nombre_articulo=this.modelEditable.nombre_articulo;
                        articuloNew.codigo_articulo=this.modelEditable.codigo_articulo;
                        articuloNew.cantidad= parseFloat(this.cantidadEditable);
                        articuloNew.lotes =[];

                        let dataAfecta = {
                            id_sucursal: this.model.id_sucursal_origen,  //Requerido
                            id_articulo: this.modelEditable.id_articulo,  //Requerido
                            cantidad: parseFloat(this.cantidadEditable)*-1, //Requerido   Se hace negativo porque se descuenta de la sucursal origen
                            es_super: 0, //Requerido  - En este caso super siempre es 0 ya que el ajuste es hasta que se confirme
                        };

                        // Mandar llamar el de afectarInventario con es_super=0
                        window.funciones_lotes.ajusteInventario(dataAfecta).then(result =>{
                            articuloNew.lotes = result;
                            //console.log(result); //result trae array de lotes {"lote":id_lote, "cantidad":cantidad}

                            var esNuevoArticulo = true;
                            var artjson = JSON.parse(JSON.stringify(this.model.articulos));  //Para no copiar la referencia solo valores

                            //buscar si el articulo ya está en el modelo, y aumentar la cantidad y guardar los lotes
                            if(artjson.length>0){
                            artjson.forEach(e => {
                                    if(e.id_articulo == articuloNew.id_articulo){
                                        e.cantidad += parseFloat(articuloNew.cantidad);
                                        e.lotes = e.lotes.concat(articuloNew.lotes);
                                        esNuevoArticulo = false;
                                    }
                                });
                            }

                            if(esNuevoArticulo == true){
                                this.model.articulos.push(articuloNew);
                            } else
                                this.model.articulos = artjson;

                            window.axios
                                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                                .then(response => {
                                    this.model._rev = response.data.rev;
                                })
                                .catch(error => {
                                    this.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al editar el artículo. Favor de comunicarse con soporte.",
                                            footer: ""
                                        });
                                }).then(() => {
                                    this.modalEditable = false;
                                    this.$nextTick(() => this.$refs.selectarticulo.focus());
                                    window.dialogLoader.hide();
                                });


                        }).catch(err => {
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al editar el registro de traspaso. Favor de comunicarse con soporte.",
                                    footer: err
                                });
                                window.dialogLoader.hide();
                        });



                    }).catch( error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al editar artículo. Favor de comunicarse con soporte.",
                            footer: error
                        });
                        this.modalEditable=false;
                        window.dialogLoader.hide();
                    });
                }


            } catch (error) {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al editar el registro de traspaso. Favor de comunicarse con soporte.",
                    footer: ""
                });
                console.log(error);
                window.dialogLoader.hide();
            }

        }
    },



    agregarArticulo: function(){
        try {
            if (this.$refs.formajuste.validate()) {

                window.dialogLoader.show('Espere un momento por favor..');

                var articuloNew = Vue.util.extend({}, this.articulo);
                articuloNew.id_articulo = this.id_articulo;
                articuloNew.nombre_articulo=this.getNombreArticulo(articuloNew.id_articulo);
                articuloNew.codigo_articulo=this.getCodigoArticulo(articuloNew.id_articulo);
                articuloNew.descripcion=this.getDescripcionArticulo(articuloNew.id_articulo);
                articuloNew.cantidad= parseFloat(this.cantidad);
                articuloNew.lotes =[];

                let dataAfecta = {
                    id_sucursal: this.model.id_sucursal_origen,  //Requerido
                    id_articulo: this.id_articulo,  //Requerido
                    cantidad: parseFloat(this.cantidad)*-1, //Requerido   Se hace negativo porque se descuenta de la sucursal origen
                    es_super: 0, //Requerido  - En este caso super siempre es 0 ya que el ajuste es hasta que se confirme
                };

                // Mandar llamar el de afectarInventario con es_super=0
                window.funciones_lotes.ajusteInventario(dataAfecta).then(result =>{
                    articuloNew.lotes = result;
                    //console.log(result); //result trae array de lotes {"lote":id_lote, "cantidad":cantidad}

                    var esNuevoArticulo = true;
                    var artjson = JSON.parse(JSON.stringify(this.model.articulos));  //Para no copiar la referencia solo valores

                    //buscar si el articulo ya está en el modelo, y aumentar la cantidad y guardar los lotes
                    if(artjson.length>0){
                    artjson.forEach(e => {
                            if(e.id_articulo == articuloNew.id_articulo){
                                e.cantidad += parseFloat(articuloNew.cantidad);
                                e.lotes = e.lotes.concat(articuloNew.lotes);
                                esNuevoArticulo = false;
                            }
                        });
                    }

                    if(esNuevoArticulo == true){
                        this.model.articulos.push(articuloNew);
                    } else
                        this.model.articulos = artjson;


                    if(this.model._id==""){//TODO: Si es correcto, guardar en lotes_traspasos y poner el id y rev del modelo

                        let dataConsecutivo={
                            "id_sucursal":this.model.id_sucursal_origen, //requerido
                            "tabla":"lotes_traspasos", //requerido
                            "longitud":5  //Opcional, default 5  longitud del numero, por ejemplo 00001, si es 3 es 001
                            }
                        window.funciones.getConsecutivoSucursal(dataConsecutivo)
                        .then( resultConsecutivo =>{

                            this.model.nombre_sucursal_destino = this.getNombreSucursal(this.model.id_sucursal_destino);
                            this.model.fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                            var consecutivo = resultConsecutivo;
                            var anio = window.moment().format("YYYY").toString().substr(-2);
                            this.model.clave_traspaso = "T"+this.getLetraSucursal(this.model.id_sucursal_origen);
                            this.model.clave_traspaso += anio + consecutivo.toString();
                            this.model.estatus = "Pendiente";
                            var data = this.model;
                            delete data["_id"];
                            delete data["_rev"];

                            window.axios
                                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', this.model)
                                .then(response => {
                                    this.model._id = response.data.id;
                                    this.model._rev = response.data.rev;
                                })
                                .catch(error => {
                                    this.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al iniciar el registro de traspaso. Favor de comunicarse con soporte.",
                                        footer: error
                                    });
                                }).then(() => {
                                    this.id_articulo = '';
                                    this.actual = '';
                                    this.cantidad = 0;
                                    this.$nextTick(() => this.$refs.selectarticulo.focus());
                                    window.dialogLoader.hide();
                                });
                        }).catch( err => {
                            console.log(err);
                            window.dialogLoader.hide();
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al iniciar el registro de traspaso. Intente nuevamente.",
                                footer: ""
                            });
                        });


                    } else {//Si ya tenía model._id!="" entonces se manda hacer update
                        window.axios
                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                            .then(response => {
                                this.model._rev = response.data.rev;
                            })
                            .catch(error => {
                                this.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al guardar el artículo. Favor de comunicarse con soporte.",
                                        footer: ""
                                    });
                            }).then(() => {
                                this.id_articulo = '';
                                this.actual = '';
                                this.cantidad = 0;
                                this.$nextTick(() => this.$refs.selectarticulo.focus());
                                window.dialogLoader.hide();
                            });
                    }

                }).catch(err => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al iniciar el registro de traspaso. Favor de comunicarse con soporte.",
                            footer: err
                        });
                        window.dialogLoader.hide();
                });

            }
        } catch (error) {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al iniciar el registro de traspaso. Favor de comunicarse con soporte.",
                footer: ""
            });
            console.log(error);
        }

    },

    deleteArticulo: function(idArticulo){

        var descartado = this.model.articulos.filter( e => {
            return e.id_articulo == idArticulo;
        });
        if(descartado.length>0){
            window.dialogLoader.show('Espere un momento por favor..');
            var lotes = descartado[0].lotes;
            // llamar cancelarComprometido con los lotes guardados
            //Regresar el inventario comprometido al actual
            window.funciones_lotes.cancelarComprometido(lotes).then(result =>{

                //Eliminar articulo del modelo
                var filter = this.model.articulos.filter( e => {
                    return e.id_articulo != idArticulo;
                });
                this.model.articulos = filter;
                /*if(this.model.articulos.length == 0){//Si ya no tiene articulos se elimina el traspaso
                    window.axios
                        .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model._id + "?rev=" + this.model._rev)
                        .then(response => {

                        })
                        .catch(error => {
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al eliminar el artículo. Favor de comunicarse con soporte.",
                                footer: error
                            });
                        }).then(() => {
                            this.modalAjuste = false;
                            this.openModalAjuste(1);
                            this.registros.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                            window.dialogLoader.hide();
                        });
                } else {*///Si no se elimina se guarda
                    window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                        .then(response => {
                            this.model._rev = response.data.rev;
                        })
                        .catch(error => {
                            this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al eliminar el artículo. Favor de comunicarse con soporte.",
                                    footer: error
                                });
                        }).then(() => {
                            window.dialogLoader.hide();
                        });
                //}
            }).catch( error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al eliminar artículo. Favor de comunicarse con soporte.",
                    footer: error
                });
                window.dialogLoader.hide();
            });
        }
    },

    imprimirOrdenEntrada: function(row){
        var self = this;

        var img = document.createElement("img");
        JsBarcode(img, row.clave_traspaso, {format: "CODE128"});

        var columns = [
            {title: "Codigo", dataKey: "codigo_articulo"},
            {title: "Artículo", dataKey: "nombre_articulo"},
            {title: "Descripción", dataKey: "descripcion"},
            {title: "Cantidad", dataKey: "cantidad_salida"},
        ];

        //setTimeout(function(){
            var doc = new jsPDF('p', 'mm', 'letter');

            doc.setFontSize(14);
            doc.text('Orden de Entrada', 15, 23);
            doc.setFontSize(10);
            doc.text('Sucursal Origen: '+row.nombre_sucursal_origen, 15, 30);
            doc.text('Sucursal Destino: '+row.nombre_sucursal_destino, 15, 35);
            doc.text('Fecha Elaboración: '+ this.parse_date_time_mx(row.fecha), 15, 40);
            doc.text('Fecha Impresión: '+ this.parse_date_time_mx(window.moment()), 15, 45);
            doc.addImage(img.src, 'PNG', 140, 20, 50, 30);
            doc.autoTable(columns, row.articulos, {
                startY: 60,
            });

            doc.save(row.clave_traspaso+".pdf");
        //},500);
    },
    imprimirOrdenSalida: function(row){
        var self = this;

        var img = document.createElement("img");
        JsBarcode(img, row.clave_traspaso, {format: "CODE128"});

        var columns = [
            {title: "Codigo", dataKey: "codigo_articulo"},
            {title: "Artículo", dataKey: "nombre_articulo"},
            {title: "Descripción", dataKey: "descripcion"},
            {title: "Cantidad", dataKey: "cantidad"},
        ];

        //setTimeout(function(){
            var doc = new jsPDF('p', 'mm', 'letter');

            doc.setFontSize(14);
            doc.text('Orden de Salida', 15, 23);
            doc.setFontSize(10);
            doc.text('Sucursal Origen: '+row.nombre_sucursal_origen, 15, 30);
            doc.text('Sucursal Destino: '+row.nombre_sucursal_destino, 15, 35);
            doc.text('Fecha Elaboración: '+ this.parse_date_time_mx(row.fecha), 15, 40);
            doc.text('Fecha Impresión: '+ this.parse_date_time_mx(window.moment()), 15, 45);
            doc.addImage(img.src, 'PNG', 140, 20, 50, 30);
            doc.autoTable(columns, row.articulos, {
                startY: 60,
                headStyles:{fillColor:[26,189,156]}
            });

            doc.save(row.clave_traspaso+".pdf");
        //},500);
    },

    editarTraspaso: function(row){
        this.model = row;
        this.modalAjuste = true;
    },

    editarObservaciones: function(row){
        this.model = row;
        this.modalObservacion = true;
    },

    guardarObservaciones: function(){
        if (this.$refs.formobservacion.validate()) {
            window.dialogLoader.show('Espere un momento por favor..');

            window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                .then(response => {
                    window.dialogLoader.showSnackbar('Las observaciones se guardaron correctamente', {
                        color: 'success'
                    });
                })
                .catch(error => {
                    this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al guardar observaciones. Favor de intentar nuevamente.",
                            footer: error
                        });
                }).then(() => {
                    this.modalObservacion = false;
                    this.registros.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    window.dialogLoader.hide();
                });
        }
    },

    guardarTraspaso: function(){
        this.registros.items = [];
        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        this.modalAjuste=false;
    },
    cancelarTraspaso: function(row){
        this.$swal({
            type: "info",
            title: "Cuidado",
            text: "Al cancelar el traspaso se eliminarán los articulos ingresados ¿Desea cancelar?",
            footer: "",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Si",
            cancelButtonText: "No"
        }).then(resultado => {
            if (resultado.value) {

                window.dialogLoader.show('Espere un momento por favor..');

                var lotes = [];
                row.articulos.forEach(function(a){
                    for(var k in a.lotes)
                        lotes.push(a.lotes[k]);
                });

                //Regresar el inventario comprometido al actual
                window.funciones_lotes.cancelarComprometido(lotes).then(result =>{
                    window.axios
                        .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id + "?rev=" + row._rev)
                        .then(response => {
                            window.dialogLoader.showSnackbar('El traspaso se canceló correctamente..', {
                                color: 'success'
                            });
                        })
                        .catch(error => {
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al cancelar el traspaso. Favor de comunicarse con soporte.",
                                footer: error
                            });
                        }).then(() => {
                            this.modalAjuste = false;
                            this.registros.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                            window.dialogLoader.hide();
                        });
                 }).catch( error => {
                    window.dialogLoader.hide();
                   this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al cancelar el traspaso. Favor de comunicarse con soporte.",
                        footer: error
                    });
                 });
            }
        });
    },

    finalizarTraspaso: function(row){
         this.$swal({
            type: "info",
            title: "Cuidado",
            text: "Al finalizar el traspaso se descontarán definitivamente los articulos de la sucursal origen y se \
                    aumentarán en la sucursal destino. ¿Desea continuar?",
            footer: "",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Si",
            cancelButtonText: "No"
        }).then(result => {
            if (result.value) {

                window.dialogLoader.show('Espere un momento por favor..');

                var lotes = [];
                row.articulos.forEach(function(a){
                    for(var k in a.lotes)
                        lotes.push(a.lotes[k]);
                });

                //Afectar inventario origen
                window.funciones_lotes.finalizarComprometido(lotes).then(result =>{

                    //Afectar el inventario destino
                    var bulkDestino = [];
                    row.articulos.forEach(function(a){
                        for(var k in a.lotes){
                            var lote = a.lotes[k];
                            var dataDestino = {
                                id_sucursal: row.id_sucursal_destino,  //Requerido
                                id_articulo: a.id_articulo,  //Requerido
                                id_compra: "",      //Requerido
                                cantidad: parseFloat(Math.abs(lote.cantidad)), //Requerido positivo
                                precio_compra: parseFloat(lote.precio_compra), //Requerido
                                fecha_caducidad: lote.fecha_caducidad, //Opcional
                                fecha_entrada: window.moment().format("YYYY-MM-DDTHH:mm:ss"),  //Requerido
                                origen: "traspaso",
                                usuario: this.$local_storage.get("sb_usuario"),
                                };
                            bulkDestino.push(dataDestino);
                        }
                    });



                    window.funciones_lotes.nuevaEntradaInventarioBulk(bulkDestino).then(result =>{

                        row.lotes_destino = result;
                        row.estatus = "Recibido";
                        row.fecha_finalizado = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                        window.axios
                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + row._id + '?conflicts=true', row)
                            .then(response => {
                                window.dialogLoader.showSnackbar('El traspaso finalizó correctamente... ', {
                                    color: 'success'
                                });
                            })
                            .catch(error => {
                                this.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al cambiar estatus al traspaso. Favor de comunicarse con soporte.",
                                        footer: error
                                    });
                            }).then(() => {
                                this.registros.items = [];
                                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                window.dialogLoader.hide();
                            });


                    }).catch(err => {
                        window.dialogLoader.hide();
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al afectar inventario en sucursal destino. Favor de comunicarse con soporte.",
                            footer: err
                        });
                    });


                 }).catch( error => {
                    window.dialogLoader.hide();
                   this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al afectar inventario de sucursal origen. Favor de comunicarse con soporte.",
                        footer: error
                    });
                 });
            }
        });
    },

    traspasar: function() {

      if (this.$refs.formajuste.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');

        let data = {
            type: this.model.type,
            id_sucursal: this.model.id_sucursal_origen,
            nombre_sucursal: this.getNombreSucursal(this.model.id_sucursal_origen),
            id_articulo: this.id_articulo,
            nombre_articulo: this.getNombreArticulo(this.id_articulo),
            codigo_articulo: this.getCodigoArticulo(this.id_articulo),
            descripcion: this.getDescripcionArticulo(this.id_articulo),
            cantidad: parseFloat(this.cantidad),
            tipo: this.model.tipo,
            comentarios: this.model.comentarios,
            usuario: this.model.usuario,
            fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
            lotes: [],
        };

        let dataAfecta = {
          id_sucursal: this.model.id_sucursal_origen,  //Requerido
          id_articulo: this.id_articulo,  //Requerido
          cantidad: this.model.tipo=="Aumentar"? parseFloat(this.cantidad) : parseFloat(this.cantidad)*-1, //Requerido
          es_super: 1, //Requerido  - En este caso super siempre es 1 ya que el ajuste es directo
        };

        window.funciones_lotes.ajusteInventario(dataAfecta).then(result =>{
            data.lotes = result;
            //console.log(result); //result trae array de lotes {"lote":id_lote, "cantidad":cantidad, "precio_compra", "fecha_caducidad"}

            window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
            .then(response => {
                window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                color: 'success'
                });
                this.modalAjuste = false;
                this.registros.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar el registro.",
                    footer: error
                });
            }).then(() => {
                window.dialogLoader.hide();
            });

        }).catch(err => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar el registro.",
                    footer: err
                });
                window.dialogLoader.hide();
        });

      }
    },

    consultar: function(){
        if(this.model.id_sucursal_origen != null && this.model.id_sucursal_origen != "" && this.model.id_sucursal_origen != "0"
            && this.id_articulo != null && this.id_articulo != "") {

        window.dialogLoader.show('Espere un momento por favor..');

        let data = {
            id_articulo: this.id_articulo,  //Requerido
            id_sucursal: this.model.id_sucursal_origen,  //Requerido
        };

        window.funciones_lotes.consultaExistencia(data).then(result =>{
            if(result.length>0){
                this.actual = parseFloat(parseFloat(result[0].existencia).toFixed(2));
            } else {
                this.$swal({
                    type: "info",
                    title: "¡Operación no Permitida!",
                    text: "No hay entrada en el inventario para este artículo.",
                    footer: ""
                });
                this.id_articulo = "";
                this.actual = "";
            }

        }).catch(err => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar existencia de artículo.",
                footer: err
            });
        }).then(() => {
            window.dialogLoader.hide();
            this.cantidad="";
            this.$nextTick(() => this.$refs.cantidadtraspaso.focus());

          });
      }
    },
    consultarEditable: function(id_sucursal, id_articulo, cantidadOriginal){

        window.dialogLoader.show('Espere un momento por favor..');

        let data = {
            id_articulo: id_articulo,  //Requerido
            id_sucursal: id_sucursal,  //Requerido
        };

        window.funciones_lotes.consultaExistencia(data).then(result =>{
            if(result.length>0){
                this.actualEditable = parseFloat(result[0].existencia.toFixed(2)) + parseFloat(cantidadOriginal);
            } else {
                this.$swal({
                    type: "info",
                    title: "¡Operación no Permitida!",
                    text: "No hay entrada en el inventario para este artículo.",
                    footer: ""
                });
                this.actualEditable = "";
            }

        }).catch(err => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar existencia de artículo.",
                footer: err
            });
        }).then(() => {
            window.dialogLoader.hide();
          });

    },
    getArticulos:function(){
        this.isLoading = true;
        let data = {
            "selector": {
                "type": "articulos",
                "estatus":"Activo",
                //"proveedores.0": { "$exists": true }
            },
            "fields":["_id","nombre","descripcion","codigo_barras","precios_venta"],
            "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
        };
        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                response.data.docs.sort(this.ordenar_nombre);
                this.articulos = response.data.docs;
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener los articulos.",
                    footer: ""
                });
            }).then(() => {
                this.isLoading = false;
            });
    },

    get_nombre_articulo:function(row){      
      return row.codigo_barras.join(", ")+" - "+row.nombre;
    },


  }
};
</script>
<style scoped>
.footer-hide>>>.VuePagination {
  display: none;
}
.bg-diferente {
    background-color: rgba(238, 92, 92, 0.813) !important;    
}
.bg-correcto {
    background-color: rgb(93, 160, 93) !important;
}

@media print
{
    .non-printable { display: none; }
    .printable { display: block; }
}
</style>
